<script setup>
import PolicyInitialDetails from "@/components/ui/baseComponents/policies/PolicyInitialDetails.vue";
import { computed, onMounted, reactive, ref, defineProps } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { required, maxLength } from "@vuelidate/validators";
import Swal from "sweetalert2";
import {
  STORE_A_COURSE_ACTION,
  GET_CURRENT_COURSE_GETTER,
} from "@/store/modules/MA/staff-trainings/constants";

import accordion from "@/components/ui/baseComponents/accordion.vue";
import NewModule from "./NewModule.vue";

const router = useRouter();
const props = defineProps({
  mode: {
    type: String,
    default: "create",
  },
  formData: {
    type: Object,
    required: false,
    default: () => {},
  },
});

const store = useStore();

const PolicyInitialDetailsRef = ref(null);

onMounted(async () => {
  await store.dispatch("admin/loadRoles", 1);
});

const already_has_course_in_store = computed(() => {
  return store.getters[`Trainings/${GET_CURRENT_COURSE_GETTER}`];
});

// const toast = inject("toast");

const getRoles = computed(() => {
  return store.getters["admin/getRoles"];
});

const rules = {
  name: { required, maxLength: maxLength(100) },
  description: { required, maxLength: maxLength(500) },
  role: { required },
};
const state = reactive({
  name: props.mode === "edit" ? props.formData.name : "",
  description: props.mode === "edit" ? props.formData.description : "",
  role:
    props.mode === "edit"
      ? props.formData.roles.map((item) => ({
          label: item.name,
          code: item.id,
        }))
      : null,
  type: props.mode === "edit" ? props.formData.type : "digital",
});

const inputsDetails = [
  {
    title: "Course Name",
    name: "name",
    placeholder: "I.e Course for General Users",
    type: "text",
    required: true,
    tag: "input",
  },
  {
    title: "Course Description",
    name: "description",
    placeholder: "I.e Course description",
    tag: "textarea",
    required: true,
  },
  {
    title: "Applied to",
    name: "role",
    placeholder: "I.e Course for roles",
    tag: "select",
    required: true,
    options: getRoles.value,
  },
];

const newModules = computed({
  get() {
    return already_has_course_in_store.value?.modules;
  },
  set(value) {
    const allModules = [...already_has_course_in_store.value.modules, value];
    return allModules;
  },
});

// const CreateNewModule = async () => {
//   await PolicyInitialDetailsRef.value.validator.$validate();
//   const formInvalid = await PolicyInitialDetailsRef.value.checkValidations();
//   if (formInvalid) {
//     toast.show("please save the course details before continuing...");
//     return false;
//   }
//   newModules.value.push({
//     name: "New Module",
//   });
// };

const saveInitialInformation = async (info) => {
  const data = {
    ...info,
    roles: info.role.map((item) => ({ role: item.code })),
  };
  delete data.role;
  const response = await store.dispatch(
    `Trainings/${STORE_A_COURSE_ACTION}`,
    data
  );
  if (response.status === 201) {
    Swal.fire({
      title: "Done",
      text: "successfully done the operation",
      icon: "success",
    });
    router.back();
  }
};
const addNewLesson = (item) => {
  if (item.lessons?.length) {
    return item.lessons.push({});
  }
  item.lessons = [];
  item.lessons.push({});
};

const reRoute = () => {
  router.back();
};
</script>

<template>
  <div class="w-full h-full flex flex-col px-5">
    <div
      class="flex items-center text-teal my-5 cursor-pointer w-24"
      @click="reRoute"
    >
      <span class="material-icons-outlined"> arrow_back </span>
      go Back
    </div>
    <div class="flex bg-gray-100 rounded-md p-5 border border-gray-300">
      <PolicyInitialDetails
        :inputRules="rules"
        :inputState="state"
        :inputsDetails="inputsDetails"
        :showSaveButton="Boolean(true)"
        :multiple_options="true"
        :disabledInputs="Boolean(already_has_course_in_store?.id)"
        ref="PolicyInitialDetailsRef"
        @saveForm="saveInitialInformation"
      >
        <template #extraInputs>
          <div class="flex">
            <label class="w-52"> Course Type : </label>
            <label class="inline-flex items-center">
              <input
                :disabled="props.mode === 'edit'"
                v-model="state.type"
                required
                type="radio"
                name="exitInterviewYes"
                value="digital"
              />
              <span class="mx-2">Digital</span>
            </label>
            <label class="inline-flex items-center">
              <input
                :disabled="props.mode === 'edit'"
                v-model="state.type"
                required
                type="radio"
                name="exitInterviewYes"
                value="physical"
              />
              <span class="mx-2">Physical</span>
            </label>
          </div>
        </template>
      </PolicyInitialDetails>
    </div>
    <div class="flex justify-end my-3">
      <!-- <div
        @click="CreateNewModule"
        class="bg-teal px-3 py-2 rounded-md text-white cursor-pointer"
      >
        Add new Module
      </div> -->
    </div>
    <div class="flex flex-col gap-2">
      <accordion
        v-for="(item, index) in newModules"
        :key="index"
        :questionsData="item.lessons || []"
        :title="item.name"
        :accordionText="'Lessons'"
      >
        <div class="p-5 bg-gray-100 rounded-md">
          <NewModule
            :data="item"
            :index="index"
            @addLesson="
              () => {
                addNewLesson(item);
              }
            "
          />
        </div>
      </accordion>
    </div>
  </div>
</template>