<script setup>
import { ref, defineProps, computed } from "vue";
import { useStore } from "vuex";
import { STORE_A_MODULE_LESSON_ACTION } from "@/store/modules/MA/staff-trainings/constants";
import Swal from "sweetalert2";

const store = useStore();
const props = defineProps({
  mainData: {
    type: Object,
    default: () => {},
  },
  data: {
    type: Object,
    default: () => {},
  },
  lesson_Index: {
    type: Number,
    required: true,
  },
});

const lessonName = ref(props.data?.name || "");
const lessonStartDate = ref(props.data?.start_date || "");
const lessonDueDate = ref(props.data?.due_date || "");
const lessonDescription = ref(props.data?.description || "");
const lessonURL = ref(props.data?.url || "");
const showButtonValue = ref(true);
const showSaveButton = computed({
  get() {
    return !Boolean(props.data.id) || showButtonValue.value;
  },
  set(value) {
    showButtonValue.value = value;
  },
});

const submitForm = async () => {
  const data = {
    name: lessonName.value,
    start_date: lessonStartDate.value,
    due_date: lessonDueDate.value,
    description: lessonDescription.value,
    url: lessonURL.value,
    module: props.mainData.id,
  };
  const response = await store.dispatch(
    `Trainings/${STORE_A_MODULE_LESSON_ACTION}`,
    {
      data,
      index: props.lesson_Index,
      course: props.mainData.course,
      module: props.mainData.id,
      newModule: !Boolean(props.data.id),
    }
  );
  if (response.status === 200) {
    Swal.fire({
      title: "Done",
      text: "successfully Saved the Lesson",
      icon: "success",
    });
    showSaveButton.value = false;
  } else {
    Swal.fire({
      title: "Failed",
      text: "There was an error saving the Lesson",
      icon: "error",
    });
  }
};
</script>
<template>
  <form @submit.prevent="submitForm">
    <div class="flex gap-5">
      <div class="w-1/3">
        <label for="startDate">Start Date:</label>
        <input
          v-model="lessonStartDate"
          id="startDate"
          type="date"
          class="px-3 py-2 rounded-md my-2 w-full"
          placeholder="start date"
        />
        <label for="dueDate">Due Date:</label>
        <input
          v-model="lessonDueDate"
          id="dueDate"
          type="date"
          class="px-3 py-2 rounded-md my-2 w-full"
          placeholder="end date"
        />
      </div>
      <div class="w-2/3">
        <div class="flex flex-col">
          <input
            v-model="lessonName"
            type="text"
            class="px-3 py-2 rounded-md my-2"
            placeholder="Lesson Name"
          />
          <textarea
            v-model="lessonDescription"
            class="rounded-lg w-full h-24 text-sm p-2"
            placeholder="Lesson Description"
          ></textarea>
          <input
            v-model="lessonURL"
            type="text"
            class="px-3 py-2 rounded-md my-2"
            placeholder="Lesson URL"
          />
        </div>
      </div>
    </div>
    <div v-if="showSaveButton" class="flex justify-center mt-4">
      <base-button type="submit"> Save</base-button>
    </div>
  </form>
</template>