<template>
  <div class="">
    <div class="relative inline-block w-10 mr-2 align-middle select-none">
      <input
        :disabled="disabled"
        @click="toggle"
        v-model="toggleValue"
        type="checkbox"
        name="toggle"
        :id="labelID"
        class="
          outline-none
          focus:outline-none
          right-4
          checked:right-0
          duration-200
          ease-in
          absolute
          block
          w-6
          h-6
          rounded-full
          bg-white
          border-4
          appearance-none
          cursor-pointer
        "
        :class="'checked:' + checkedColour"
      />
      <label
        :for="labelID"
        class="
          block
          overflow-hidden
          h-6
          rounded-full
          bg-gray-300
          cursor-pointer
        "
      >
      </label>
    </div>
    <label :for="labelID" class="text-gray-400 font-medium">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    labelID: {
      type: String,
      required: true,
    },
    checkedColour: {
      type: String,
      default: "bg-blue-500",
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statusMarker: false,
    };
  },
  computed: {
    toggleValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.statusMarker = value;
        this.$emit("update:value", value);
      },
    },
  },

  methods: {
    toggle() {
      this.statusMarker = !this.statusMarker;
      this.$emit("toggleStatus", this.statusMarker);
    },
  },
};
</script>
