<script setup>
import { ref, defineProps, computed } from "vue";
import { useStore } from "vuex";
import {
  STORE_A_COURSE_MODULE_ACTION,
  GET_CURRENT_COURSE_GETTER,
} from "@/store/modules/MA/staff-trainings/constants";
import ToggleComponent from "@/components/ui/baseComponents/ToggleComponent.vue";
import Swal from "sweetalert2";

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  index: {
    type: Number,
    required: true,
  },
});

const store = useStore();
const durationInDays = ref(false);
const Recurring = ref(false);
const ModuleRequired = ref(Boolean(props.data.is_required) || false);
const ModuleExamRequired = ref(Boolean(props.data.is_exam_required) || false);
const Reminder = ref(false);
const reminderFrequency = ref(parseInt(props.data.reminder) || "");
const moduleRecurrsion = ref(parseInt(props.data.frequency) || "");
const moduleDuration = ref(parseInt(props.data.duration) || "");
const moduleName = ref(props.data.name || "");
const showButtonValue = ref(true);
const showSaveButton = computed({
  get() {
    return !Boolean(props.data.id) || showButtonValue.value;
  },
  set(value) {
    showButtonValue.value = value;
  },
});

const submitForm = async () => {
  const data = {
    name: moduleName.value,
    reminder: `${reminderFrequency.value}`,
    frequency: `${moduleRecurrsion.value} days`,
    is_required: ModuleRequired.value ? 1 : 0,
    duration: durationInDays.value
      ? `${moduleDuration.value} days`
      : `${moduleDuration.value} months`,
    course: store.getters[`Trainings/${GET_CURRENT_COURSE_GETTER}`].id,
    is_exam_required: ModuleExamRequired.value ? 1 : 0,
  };
  const response = await store.dispatch(
    `Trainings/${STORE_A_COURSE_MODULE_ACTION}`,
    {
      data,
      index: props.index,
      newModule: !Boolean(props.data.id),
    }
  );
  if (response.status === 200) {
    Swal.fire({
      title: "Done",
      text: "successfully Saved the Module",
      icon: "success",
    });
    showSaveButton.value = false;
  } else {
    Swal.fire({
      title: "Failed",
      text: "There was an error saving the module",
      icon: "error",
    });
  }
};
</script>
<template>
  <form @submit.prevent="submitForm" class="rounded-md bg-gray-200 px-4 py-3">
    <div class="flex flex-col">
      <label for="name">Module Name:</label>
      <input
        v-model="moduleName"
        id="name"
        type="text"
        class="px-3 py-2 rounded-md my-2"
        placeholder="I.e Course for Clinical Assitants"
      />
      <div class="flex justify-between items-center my-2">
        <label>Required Module</label>
        <toggle-component
          v-model:value="ModuleRequired"
          label=""
          labelID="Required"
          checkedColour="bg-teal"
        />
      </div>
      <div class="flex justify-between items-center my-2">
        <label>Required Exam</label>
        <toggle-component
          label=""
          labelID="RequiredExam"
          checkedColour="bg-teal"
          v-model:value="ModuleExamRequired"
        />
      </div>

      <div class="flex justify-between items-center my-2">
        <label for="duration">Module Duration</label>
        <toggle-component
          label="Duration in Days"
          labelID="days"
          checkedColour="bg-teal"
          v-model:value="durationInDays"
        />
      </div>
      <input
        v-model="moduleDuration"
        id="duration"
        type="number"
        class="px-3 py-2 rounded-md my-2"
        :placeholder="`module duration in ${
          durationInDays ? 'days' : 'months'
        }`"
      />
      <div class="flex justify-between items-center my-2">
        <label>Frequency / Recurring Time Period (days)</label>
        <toggle-component
          label=""
          labelID="recurring"
          checkedColour="bg-teal"
          v-model:value="Recurring"
        />
      </div>
      <input
        v-if="Recurring"
        v-model="moduleRecurrsion"
        id="recurring"
        type="number"
        class="px-3 py-2 rounded-md my-2"
        placeholder="e.g 350 days"
      />

      <div class="flex justify-between items-center my-2">
        <label>Reminder</label>
        <toggle-component
          label=""
          labelID="reminder"
          checkedColour="bg-teal"
          v-model:value="Reminder"
        />
      </div>
      <input
        v-if="Reminder"
        v-model="reminderFrequency"
        id="reminder"
        type="number"
        class="px-3 py-2 rounded-md my-2"
        placeholder="e.g 10 days"
      />
    </div>
    <div v-if="showSaveButton" class="flex justify-center mt-4">
      <base-button type="submit"> Save</base-button>
    </div>
  </form>
</template>