<script setup>
import { computed, defineProps, defineEmits } from "vue";
import accordion from "@/components/ui/baseComponents/accordion.vue";
import NewLessonConfig from "./NewLessonConfig.vue";
import Swal from "sweetalert2";

const emits = defineEmits(["addLesson"]);
const props = defineProps({
  data: {
    type: Object,
    requried: true,
  },
});
const buttenEnabler = computed(() => {
  return Boolean(props.data?.id);
});

const lessons = computed({
  get() {
    return props.data.lessons;
  },
  set(value) {
    return value;
  },
});
const addNewLesson = () => {
  if (buttenEnabler.value) {
    return emits("addLesson");
  }
  Swal.fire({
    icon: "error",
    title: "Error",
    text: "Please create and save module for creating Lesson",
  });
};
</script>
<template>
  <div class="h-full flex flex-col justify-between bg-gray-200 rounded-md">
    <div class="p-5 flex flex-col gap-2">
      <div class="lesson" v-for="(item, index) in lessons" :key="index">
        <accordion
          :questionsData="[]"
          :title="'lesson 1'"
          :accordionText="''"
          :isInner="true"
        >
          <div class="p-5 bg-gray-100 rounded-md">
            <NewLessonConfig
              :lesson_Index="index"
              :mainData="props.data"
              :data="item"
            />
          </div>
        </accordion>
      </div>
    </div>
    <div class="flex flex-col flex-grow justify-end">
      <div class="flex justify-end gap-5 pr-5 pb-5">
        <div
          :class="[{ 'bg-orange': !buttenEnabler }]"
          @click="addNewLesson"
          class="py-2 px-4 text-white bg-teal rounded-full cursor-pointer"
        >
          +
        </div>
      </div>
    </div>
  </div>
</template>